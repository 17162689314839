import React, { useEffect, useTransition } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
//import mockServer from './mock'
import './lang'
import { createBrowserHistory } from 'history';
import { Alert, Button } from 'antd';
import packageJson from '../package.json';
 
const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};
 
const environment = process.env.NODE_ENV
const moment = require('moment-timezone'); 
/*if (environment !== 'production') {
	mockServer({ environment })
}*/

const MAX_SESSION_DURATION = 1000 * 60 * 60; // e.g., 1 hour in milliseconds



function App() {
  useEffect(() => {
    const checkSessionValidity = () => {
        const sessionStart = localStorage.getItem('sessionStart');
        const currentTime = new Date().getTime();

        if (!sessionStart || currentTime - sessionStart > MAX_SESSION_DURATION) {
            // Clear session data
            localStorage.clear();
            // Redirect to login or show login prompt
            // For example, using React Router: history.push('/login');
           // alert('Session expired. Please login again.');
           history.push('/login');
        }
    };

    checkSessionValidity();

    // Optional: Check session validity at regular intervals
    const interval = setInterval(checkSessionValidity, 10000); // Every 10 seconds

    return () => clearInterval(interval);
}, []);

    useEffect(() => {
   
    window.onbeforeunload = () => {
      window.sessionStorage.setItem('lastRoute', JSON.stringify(window.location.pathname))
  }
  document.onkeydown = (e) => {
    if (e.key == 123) {
        e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key == 'I') {
        e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key == 'C') {
        e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key == 'J') {
        e.preventDefault();
    }
    if (e.ctrlKey && e.key == 'U') {
        e.preventDefault();
    }
};
  }, [])
  return (
    <div className="App">
    
      <Provider store={store}>
        <BrowserRouter history={createBrowserHistory}>
          <ThemeSwitcherProvider 
            themeMap={themes} 
            defaultTheme={THEME_CONFIG.currentTheme} 
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>  
      </Provider>
    </div>
  );
}

export default App;
