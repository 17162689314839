import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';
import i18n from 'lang';

const unauthorizedCode = [401, 403]

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 1200000
})
const translate=(s)=>{
 
	return i18n.t(s)
}
// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
	
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
	}

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response?.use( (response) => {
	return response?.data
}, (error) => {

	let notificationParam = {
		message: ''
	}
	if (unauthorizedCode.includes(error.response?.status)) {
		notificationParam.message = translate('Authentication Fail')
 		notificationParam.description = error.response?.data.message? translate(error.response?.data.message) :translate('Please login again')
 		  localStorage.removeItem(AUTH_TOKEN)
		//localStorage.removeItem('firstLogin')
         localStorage.removeItem('isAuthed')
        localStorage.removeItem('role')
		window.location.replace('/')
		store.dispatch(signOutSuccess())  
	}
 	

	if (error.response?.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error.response?.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}
	
	if (error.response?.status === 508) {
		notificationParam.message = 'Time Out'
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service