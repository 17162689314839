import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Routes from 'routes'
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import userService from 'services/UserService';
import { addRole } from 'store/slices/authSlice';

export const Views = () => {
	const { locale, direction } = useSelector(state => state.theme);
	const currentAppLocale = resources[locale];
	useBodyClass(`dir-${direction}`);
	const role = useSelector(state => state.auth.role);
	const [user, setUser] = useState({ name: '', lname: '', email: '' })
	const token = useSelector(state => state.auth.token);
 	const dispatch = useDispatch()

	const fetchMe = async () => {
		if (token) {
			try {
				let resp = await userService.getMe()
				resp = resp.user
				localStorage.setItem('role', role)
				if (resp.email !== '') {
					setUser(resp)
					dispatch(addRole(resp.role))
				}
			} catch (errors) {
				console.log(errors)
			}
		}
	}
	useEffect(() => {
		if(!localStorage.getItem('role') && token){
			fetchMe().then(() => {window.location.reload()})
				
				}
			}, [])
	useEffect(() => {
        if (window === undefined) {
            return
        }
        const root = window.document.documentElement
        root.setAttribute('dir', direction)
    }, [direction]);
	return (
		<ConfigProvider direction={direction} locale={currentAppLocale.antd}>
			<Routes role={role}/>
		</ConfigProvider>
	)
}

export default Views;