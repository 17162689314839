import fetch from 'auth/FetchInterceptor'
import fetchnoSec from 'auth/FetchInterceptorRequests'
import fetchNoAuth from 'auth/FetchInterceptorRequests'

const userService = {}

userService.getMe = function () {
  return fetch({
    url: '/users/me',
    method: 'get'
  })
}
userService.getMyData = function () {
  return fetch({
    url: '/users/mydata',
    method: 'get'
  })
}
userService.updateUser = function (user) {
  return fetch({
    url: '/users/'+user.id,
    method: 'put',
    data: user
  })
}
userService.allUsers = function () {
  return fetchnoSec({
    url: '/users',
    method: 'get'
  })
}
userService.getAll = function () {
  return fetch({
    url: '/users/important',
    method: 'get'
  })
}
userService.getAdmins = function () {
  return fetch({
    url: '/users/admins',
    method: 'get'
  })
}
userService.getMasters = function () {
  return fetchNoAuth({
    url: '/users/masters',
    method: 'get'
  })
}
userService.getAllUsers = function () {
  return fetchNoAuth({
    url: '/users',
    method: 'get'
  })
}
 
userService.getLcf = function () {
  return fetch({
    url: '/users/lcf',
    method: 'get'
  })
}
userService.getLcfClean = function () {
  return fetch({
    url: '/users/lcf/clean',
    method: 'get'
  })
}
userService.getTeachers = function () {
  return fetch({
    url: '/users/teachers',
    method: 'get'
  })
}
export default userService ;