import React, { useEffect, useState } from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import {lcfRoutes, protectedRoutes, publicRoutes, adminRoutes, userRoutes, masterRoutes,teacherRoutes } from 'configs/RoutesConfig';
import AppRoute from './AppRoute';
import userService from 'services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { addRole } from 'store/slices/authSlice'
import { createBrowserHistory } from 'history';

const Routes = (props) => {
	const [user, setUser] = useState({ name: '', lname: '', email: '' })
	const token = useSelector(state => state.auth.token);
 	const dispatch = useDispatch()


	return (
		<RouterRoutes>
			{props.role == "admin" &&  adminRoutes && adminRoutes.map((route, index) => {
				return (
					<Route
						key={route.key + index}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}
			{props.role == "lcf"  && lcfRoutes && lcfRoutes.map((route, index) => {
				return (
					<Route
						key={route.key + index}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}

			{props.role == "master" && masterRoutes && masterRoutes.map((route, index) => {
				return (
					<Route
						key={route.key + index}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}
				{props.role == "teacher" && teacherRoutes && teacherRoutes.map((route, index) => {
				return (
					<Route
						key={route.key + index}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}
				{protectedRoutes.map(route => {
				return (
					<Route
						key={route.path}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}
			{publicRoutes.map(route => {
				return (
					<Route
						key={route.path}
						path={route.path}
						element={
							<AppRoute
								routeKey={route.key}
								component={route.component}
								{...route.meta}
							/>
						}
					/>
				)
			})}

 {!token && <Route path="*" element={<Navigate to="/" />} />}
{token && <Route path="*" element={<Navigate to="/" />} />} 
		</RouterRoutes> 
	)
}

export default Routes