import fetch from 'auth/FetchInterceptor'
import fetchRequest from 'auth/FetchInterceptorRequests'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/users/login',
		method: 'post',
		data: data
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/users/register',
		method: 'post',
		data: data
	})
}
AuthService.newUser = function (data) {
	return fetchRequest({
		url: '/users/newregister',
		method: 'post',
		data: data
	})
}
AuthService.update = function (data) {
	return fetchRequest({
		url: '/users/'+data.id,
		method: 'put',
		data: data
	})
}
AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;