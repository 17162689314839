import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `/`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.versioninfo",
    path: `${APP_PREFIX_PATH}/versioninfo`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/versioninfo")
    ),
  },
  {
    key: "dashboard.groups",
    path: `${APP_PREFIX_PATH}/groups`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/groups")
    ),
  },
  {
    key: "reports.mf",
    path: `${APP_PREFIX_PATH}/reports/mf`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/reports/masters")
    ),
  },  
  {
    key: "reports.finans",
    path: `${APP_PREFIX_PATH}/reports/finans`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/reports/finans")
    ),
  },
  {
    key: "reports.mf",
    path: `${APP_PREFIX_PATH}/reports/lcf`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/reports/lcf")
    ),
  },
  {
    key: "reports.teacher",
    path: `${APP_PREFIX_PATH}/reports/teachers`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/reports/teacher")
    ),
  },
  {
    key: "dashboard.students",
    path: `${APP_PREFIX_PATH}/users/students`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students")
    ),
  },
  {
    key: "dashboard.students-exited",
    path: `${APP_PREFIX_PATH}/users/students-exited`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students-exited")
    ),
  },
  {
    key: "dashboard.students-unasigned",
    path: `${APP_PREFIX_PATH}/users/students-unasigned`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students-unasigned")
    ),
  },
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "dashboard.default.chat",
    path: `${APP_PREFIX_PATH}/dashboards/default/chat`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/chat_main")
    ),
  },
  {
    key: "dashboard.default.shop",
    path: `${APP_PREFIX_PATH}/dashboards/default/shop/*`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/shop/product-list")
    ),
  },
  {
    key: "dashboard.default.shop",
    path: `${APP_PREFIX_PATH}/dashboards/default/calendar/*`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/calendar")
    ),
  },
  {
    key: "dashboard.default.report",
    path: `${APP_PREFIX_PATH}/dashboards/default/report/*`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/report")
    ),
  },
  {
    key: "dashboard.default.report.quick",
    path: `${APP_PREFIX_PATH}/dashboards/default/report-quick/*`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/report-quick")
    ),
  },
  {
    key: "dashboard.default.report.short",
    path: `${APP_PREFIX_PATH}/dashboards/default/report-short/*`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/report-short")
    ),
  },
  {
    key: "dashboard.library",
    path: `${APP_PREFIX_PATH}/library`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/biblioteka_new")
    ),
  },
  {
    key: "apps-help",
    path: `${APP_PREFIX_PATH}/dashboards/default/help/*`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/help")
    ),
  },
  
  {
    key: "dashboard.discountstudents",
    path: `${APP_PREFIX_PATH}/discountsstudents`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/discountsstudents")
    ),
  }
];

export const adminRoutes = [
   
  {
    key: "dashboard.default.fix",
    path: `${APP_PREFIX_PATH}/dashboards/default/fix/*`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/fix")
    ),
  },
  {
    key: "dashboard.admin",
    path: `/`,
    component: React.lazy(() => import("views/app-views/dashboards/admin")),
  },
  {
    key: "dashboard.users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/users")
    ),
  },
  {
    key: "dashboard.lcf",
    path: `${APP_PREFIX_PATH}/users/lcf`,
    component: React.lazy(() => import("views/app-views/dashboards/admin/lcf")),
  },
  {
    key: "dashboard.masters",
    path: `${APP_PREFIX_PATH}/users/masters`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/masters")
    ),
  },
  {
    key: "dashboard.users.admins",
    path: `${APP_PREFIX_PATH}/users/admins`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/admins")
    ),
  },
  {
    key: "dashboard.teachers",
    path: `${APP_PREFIX_PATH}/users/teachers`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/teachers")
    ),
  },
  {
    key: "dashboard.students",
    path: `${APP_PREFIX_PATH}/users/students`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students")
    ),
  },
  {
    key: "dashboard.students-exited",
    path: `${APP_PREFIX_PATH}/users/students-exited`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students-exited")
    ),
  },
  {
    key: "dashboard.students-unasigned",
    path: `${APP_PREFIX_PATH}/users/students-unasigned`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students-unasigned")
    ),
  },
  {
    key: "dashboard.countries",
    path: `${APP_PREFIX_PATH}/offices/countries`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/countries")
    ),
  },
  {
    key: "dashboard.licences",
    path: `${APP_PREFIX_PATH}/licences`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/licences")
    ),
  },
  {
    key: "dashboard.courses",
    path: `${APP_PREFIX_PATH}/courses`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/courses")
    ),
  },
 
  {
    key: "dashboard.centers",
    path: `${APP_PREFIX_PATH}/offices/centers`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/centers")
    ),
  },
  {
    key: "dashboard.discounts",
    path: `${APP_PREFIX_PATH}/discounts`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/discounts")
    ),
  }
  ,
  {
    key: "dashboard.tasks",
    path: `${APP_PREFIX_PATH}/tasks`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/default/tasks")
    ),
  }
];

export const userRoutes = [
  {
    key: "dashboard.user",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboards/user")),
  },
];
export const masterRoutes = [
  
  {
    key: "dashboard.master",
    path: `/`,
    component: React.lazy(() => import("views/app-views/dashboards/master")),
  },
  {
    key: "reports.finansmf",
    path: `${APP_PREFIX_PATH}/reports/finansmf`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/reports/finansmf")
    ),
  },
  {
    key: "dashboard.users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/users")
    ),
  },
  {
    key: "dashboard.lcf",
    path: `${APP_PREFIX_PATH}/users/lcf`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/lcf")
    ),
  },

  {
    key: "dashboard.teachers",
    path: `${APP_PREFIX_PATH}/users/teachers`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/teachers")
    ),
  },
  {
    key: "dashboard.students",
    path: `${APP_PREFIX_PATH}/users/students`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students")
    ),
  },
  {
    key: "dashboard.students-exited",
    path: `${APP_PREFIX_PATH}/users/students-exited`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students-exited")
    ),
  },
  {
    key: "dashboard.students-unasigned",
    path: `${APP_PREFIX_PATH}/users/students-unasigned`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students-unasigned")
    ),
  },
  {
    key: "dashboard.centers",
    path: `${APP_PREFIX_PATH}/offices/centers`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/master/centers")
    ),
  }
];


export const lcfRoutes = [
  {
    key: "dashboard.lcf",
    path: `/`,
    component: React.lazy(() => import("views/app-views/dashboards/lcf")),
  },
  {
    key: "dashboard.users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/users")
    ),
  },

  {
    key: "dashboard.teachers",
    path: `${APP_PREFIX_PATH}/users/teachers`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/lcf/teachers")
    ),
  },
  {
    key: "dashboard.students",
    path: `${APP_PREFIX_PATH}/users/students`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students")
    ),
  },
  {
    key: "dashboard.students-exited",
    path: `${APP_PREFIX_PATH}/users/students-exited`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students-exited")
    ),
  },
  {
    key: "dashboard.students-unasigned",
    path: `${APP_PREFIX_PATH}/users/students-unasigned`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/admin/students-unasigned")
    ),
  },
  {
    key: "dashboard.centers",
    path: `${APP_PREFIX_PATH}/offices/centers`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/master/centers")
    ),
  },
];

export const teacherRoutes = [
  {
    key: "dashboard.teacher",
    path: `/`,
    component: React.lazy(() => import("views/app-views/dashboards/teacher")),
  }
];
